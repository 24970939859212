.guestlist-header {
    align-items: center;
    flex-direction: row;

    .back-button {
        grid-column: 1 / span 2;
    }

    .save-button {
        justify-self: flex-end;
        width: max-content;

        h2 {
            margin-left: 8px;
        }

        &.disabled {
            background-color: $gray2Cl;
            cursor: not-allowed;
            &:hover {
                transform: scale(1);
            }
        }
    }

    h3 {
        font-size: $midLarge;
        font-weight: 700;
    }

    .guestlist-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        max-width: 100%;
        gap: 7px;

        &.end {
            justify-content: flex-end;
        }

        &.noWrap {
            flex-wrap: nowrap;
        }
    }
}

.guestlist-info-main {
    font-size: $bigFS;
    font-weight: 700;

    &.small {
        font-size: $mediumFS;
    }

    &.gray {
        color: $gray3Cl;
    }

    &.light {
        font-weight: 500;
    }

    &.guestlist-name {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
}

.guestlist-info-sub {
    font-size: $smallFS;
    font-weight: 700;
    color: $gray2Cl;

    &.light {
        font-weight: 500;
    }
}

.guest-list-card-form {
    padding: 0;
}

.guestlist-content-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;

    &.on-hover {
        cursor: pointer;
        &:hover {
            background-color: #fafafa;
            transition: all 0.5s ease;
        }
    }

    &.modal {
        padding: 0;
        flex-wrap: wrap;
        align-self: flex-start;

        .guestlist-content-pill {
            margin-top: 5px;
            margin-right: 4px;
            background-color: $purpleCl;

            h1 {
                color: #fff;
            }

            &.disabled {
                border: 1px solid #8849D6;
                background-color: transparent;

                h1 {
                    color: #8849D6;
                }
            }
        }
    }

    &.user {
        padding: 10px 24px;

        &:hover {
            background-color: #fafafa;
            transition: all 0.3s ease;
        }
    }

    &.border {
        border-top: 1px solid #f5f5f8;
    }

    &.between {
        justify-content: space-between;
    }

    &.p0 {
        padding: 0;
    }

    &.w-100 {
        width: 100%;
    }

    .row-header-text {
        font-size: $smallFS;
        font-weight: 700;
        letter-spacing: 0.6px;
        text-transform: uppercase;
    }

    .guestlist-content-pill {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        border: 1px solid $purpleCl;
        border-radius: 8px;
        margin-top: 10px;
        margin-right: 12px;
        cursor: pointer;

        h1 {
            color: $purpleCl;
            text-transform: none;
        }
    }

    .guestlist-content-avatar {
        width: 48px;
        height: 48px;
        border-radius: 100px;
        background-color: $purpleCl;
        margin-right: 15px;

        .guestlist-content-badge {
            position: relative;
            width: 16px;
            height: 16px;
            border-radius: 100px;
            background-color: #fff;
            bottom: -65%;
            right: -70%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.guestlist-type-pill {
    background-color: $grayCl;
    margin-left: 4px;
    padding: 1px 8px;
    font-size: $smallFS;
    font-weight: 500;
    color: $gray3Cl;
    border-radius: 4px;
    cursor: pointer;
    &.disabled {
        background-color: #fff;
        border: 1px solid $grayCl;
        cursor: auto;
    }
}
.guestlist-custom-price {
    background-color: $purpleCl;
    padding: 0px 14.5px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    font-weight: 700;
    color: #fff;
    border-radius: 9px;

    &.w-fit {
        width: fit-content;
    }
}

.ant-input {
    background-color: #FAFAFA;
}

.guestlist-count-pill {
    background-color: $purpleCl;
    align-self: flex-start;
    padding: 1px 15px;
    border-radius: 20px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.08px;
    color: #fff;

    &.space-right {
        margin-right: 8px;
    }
}

.guestlist-collapse-header {
    border-bottom: 1px solid #EBEBF5 !important;

    &.active {

        .ant-collapse-header {
            color: $purpleCl !important;
        }
    }

    &.plans {
        .ant-collapse-header,
        .ant-collapse-content-box {
            padding-left: 0;
            padding-right: 0;
        }

        .anticon {
            right: 0 !important;
        }
    }

    .ant-collapse-header {
        font-size: $smallFS;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        color: $gray2Cl !important;
    }
}

.guestlist-content-footer {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.guestlist-details-modal {
    border-radius: 9px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $grayCl;
    padding: 2px;
    margin-bottom: 12px;

    .guestlist-details-modal-pill {
        padding: 6px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $purpleCl;
        cursor: pointer;
        border-radius: 7px;
        width: 50%;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
        transition: all 0.5s ease;

        &.not-selected {
            background-color: white;
            box-shadow: none;
            transition: all 0.5s ease;

            .guestlist-details-modal-text {
                font-weight: 500;
                color: $purpleCl;
            }
        }

        .guestlist-details-modal-text {
            font-size: $smallFS;
            font-weight: 700;
            color: white;
        }
    }
}

.guestlist-details-modal-data-container {
    background-color: #f5f5f8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    margin-bottom: 8px;

    &.multi {
        height: fit-content;
    }
    &.column {
        height: fit-content;
        flex-direction: column;
        justify-content: space-between;
        padding: 12px;
    }

    .guestlist-details-modal-counter {
        padding: 8px 16px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin: 0 8px;

        &.ant-input,
        &.ant-input-number {
            box-shadow: none;
            -webkit-box-shadow: none;
            border: none;
            width: fit-content;
            width: 100px;
        }

        &.ant-input-number {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;

            .ant-input-number-input {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            .ant-input-number-handler-wrap {
                display: none;
                appearance: none;
            }
        }
    }

    .right-side {
        display: flex;
        align-items: center;

        &.full {
            width: 100%;
            .ant-select,
            .ant-select-single,
            .ant-select-show-arrow {
                .ant-select-selector {
                    .ant-select-selection-item {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                    }
                }
            }

            .select {
                width: 100%;
            }
        }

        .ant-select,
        .ant-select-single,
        .ant-select-show-arrow {
            background-color: transparent;

            .ant-select-selector {
                background-color: transparent;
                border: none;
                border-radius: 8px;
                color: $purpleCl;
                min-width: 150px;

                .ant-select-selection-item {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 4px;
    }
}

.guestlist-total-card-left {
    border-right: 1px solid rgba(150, 151, 161, 0.3);
    padding-right: 12px;

    h2 {
        font-size: $smallFS;
        font-weight: 700;
        line-height: 14px;
    }

    h1 {
        font-size: $midLarge;
        font-weight: 700;
        line-height: 38.25px;
    }
}

.guestlist-total-card-right {
    text-align: right;
    h1 {
        font-size: $exLargeFS;
        font-weight: bold;
        line-height: 41px;
        letter-spacing: 0.3px;
        color: $greenCl;
    }

    h2 {
        font-size: $mediumFS;
        font-weight: 500;
        line-height: 18px;
    }
}

.guestlist-total-card-bar {
    width: 100%;
    height: 32px;
    border: 2px solid #ebebf5;
    border-radius: 16px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    position: relative;

    .guestlist-total-card-bar-inner {
        width: 0%;
        height: 100%;
        background-color: $greenCl;
    }

    .guestlist-total-card-bar-percent {
        font-size: $mediumFS;
        font-weight: 700;
        color: $greenCl;
        position: absolute;
        right: 12px;
    }
}

.guestlist-entries-options {
    width: 250px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0px 0px rgba(6, 44, 82, 0.1), 0px 1px 3px rgba(64, 66, 69, 0.12), 0px 2px 16px rgba(33, 43, 54, 0.08);
    position: absolute;
    top: 0;
    right: 25px;
    top: 50px;
    z-index: 10;
    padding: 8px 4px;
    display: flex;
    flex-direction: column;

    .guestlist-entries-row {
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        transition: all 0.5s ease;
        &:hover {
            background-color: #f5f5f8;
            transition: all 0.5s ease;
        }
        &.m0 {
            margin-bottom: 0;
        }

        &.disabled {
            background-color: #f5f5f8;
            cursor: not-allowed;
        }

        &.purple {
            background-color: $purpleCl;

            h1 {
                color: white;
            }

            &:hover {
                opacity: 0.8;
            }
        }
        h1 {
            font-size: $mediumFS;
            font-weight: 500;
            margin-left: 8px;
            color: $gray3Cl;
        }
    }
}

.import-excel-path-container {
    background-color: #f5f5f8;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;

    &.border {
        border: 2px solid $grayCl;
        cursor: pointer;
    }
    h1 {
        font-size: $mediumFS;
        font-weight: 500;
        color: $gray2Cl;
    }
}

.guestlist-details-row {
    display: flex;
    align-items: center;

    &.between {
        justify-content: space-between;
    }

    h1 {
        color: $purpleCl;
        font-size: $smallFS;
        font-weight: 700;
    }
}


.guestlist-teaser {
  border-radius: 12px;
  overflow: hidden;
  background-color: #141121;
  margin-bottom: 30px;


  .guestlist-info-square {
    width: 64px;
    height: 64px;
    object-fit: cover;
    position: relative;
    border-radius: 8px;
    background-color: white;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.07));
    margin-right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    .guestlist-info-date {
      font-size: $midLarge;
      line-height: normal;
      // margin-bottom: 10px;
    }

    .guestlist-info-month {
      font-size: $smallFS;
      font-weight: 700;
      color: $redCl;
      margin-bottom: 7px;
      text-transform: uppercase;
    }
  }

  .guestlist-teaser-image {
    height: 100px;
    position: relative;
  }

  .guestlist-teaser-info {
    padding: 17px 36px 24px 36px;
  }

  .guestlist-teaser-info-title h1 {
    color: #ffffff;
  }

  .guestlist-teaser-info-date-time {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    padding-left: 28px;
    border-left: 1px solid #9697A1;
    flex-shrink: 0;

    .guestlist-teaser-info-date {
      margin-bottom: 3px;
    }

    .guestlist-teaser-edit {
      margin-left: 15px;
      cursor: pointer;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.8;
      }

      &:focus, &:active {
        opacity: 0.5;
      }
    }
  }
}

.table-card.guestlist-table {
  margin-top: 0;

  .table-content > div {
    grid-template-columns: 1fr 145px 70px 50px;
  }

  .table-content {
    .table-content-inner {
      max-height: 475px;
    }
  }

  .table-filter-group {
    margin: 30px 0;
    gap: 10px
  }

  .user-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    align-content: center;
  }

  .user-avatar {
    flex-shrink: 0;

    .ant-avatar-square {
      border-radius: 0;
    }
  }



  .user-status{
    color: $purpleCl;
    display: flex;
    justify-content: center;
  }

  .user-edit {
    color: #A7A6AC;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: color .3s ease;

    &:hover {
      color: darken(#A7A6AC, 20%);
    }

    &:active, &:focus {
      color: darken(#A7A6AC, 40%);
    }
  }
}

.data-card.funnel-card {
  height: min-content;

  .data-card-inner {
    padding: 0 30px;
    height: 100%;
  }

  .funnel-actions {
    justify-content: right;
  }

  .funnel-data {
    padding-top: 60px;
  }

  .funnel-container {
    display: flex;
    width: 197px;
    height: 617px;
    padding-bottom: 30px;
  }

  .funnel-legend {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-left: 20px;
    flex-shrink: 0;

    .funnel-legend-item {
      font-weight: 700;
      font-size: 20px;
      color: #171A23;
      display: flex;
      align-items: center;
      gap: 5px;

      .icon {
        width: 20px;
        height: 20px;
        color: $purpleCl;
        display: flex;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .funnel-legend-status {
      font-weight: 500;
      font-size: 13px;
    }
  }
}


.open-guest-list-entry {
  display: grid;
  grid-template-columns: 1fr 1fr 120px 140px;
  border: 1px solid #F5F5F8;
  padding: 15px 0 21px 0;

  .promoter-info{
    align-items: center;

    .promoter-image {
      border-radius: 100%;
      overflow: hidden;
      margin-right: 21px;
    }

    .promoter-name {
      div {
        font-weight: 700;
        font-size: 14px;
        line-height: 23px;
        color: #34313F;
        display: flex;
        align-items: center;

        span {
          cursor: pointer;
          height: 19px;
        }
        svg {
          margin-left: 11px;
          color: #CFCFCF;
          height: 17px;
        }
      }

      span {
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        color: #A7A6AC;
      }
    }
  }

  .progress-info {
    padding: 0 40px;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #A7A6AC;
    justify-content: center;

    .progress-bar {
      margin-top: 8px;
      background: #EEE5FF;
      border-radius: 5px;
      height: 5px;
      overflow: hidden;
      position: relative;

      .progress-bar-inner {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 5px;
        border-radius: 5px;
        background: $purpleCl;
      }
    }
  }

  .limit-input {
    display: flex;
    align-items: center;
  }

  .actions {
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  }
}

.ant-dropdown.crew-member-dropdown {
  width: 220px;
}

.crew-member-menu {
  padding: 20px 14px;
  flex-shrink: 0;


  .crew-member-list {
    margin-top: 5px;
    max-height: 170px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 9px;
      height: 7px;
      border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.12);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.12);
      border-radius: 10px;
    }

    .crew-member-list-entry {
      padding: 5px 0;
      border-bottom: 1px solid #F3EDFB;
      cursor: pointer;

      .crew-member-name {
        font-weight: 600;
        font-size: 10px;
        line-height: 18px;
        display: flex;
        align-items: center;
        letter-spacing: -0.03em;
        color: #34313F;
      }
    }
  }
}

.modal-container.add-guest-entry {
  .button-delete {
    order: 0;
    background-color: $redCl;
    color: #FFFFFF;


    &:hover {
      background-color: darken($redCl, 10%);
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .button-checkin {
    order: -1;
    background-color: $greenCl;
    color: #FFFFFF;


    &:hover {
      background-color: darken($greenCl, 10%);
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.plans-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 24px;

    .plan-card {
        border: 2px solid $black;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        padding: 24px;
        position: relative;
        min-height: 501px;
        background-color: #fff;

        &.green {
            border-color: $greenCl;

            .plan-text,
            .plan-name {
                color: $greenCl;
            }

            .plan-buy-button {
                border-color: $greenCl;
                background-color: $greenCl;

                &:hover {
                    border-color: $greenCl;
                }
            }
        }

        &.purple {
            border-color: $purpleCl;

            .plan-text,
            .plan-name {
                color: $purpleCl;
            }

            .plan-main-info {
                .plan-free-trail {
                    background-color: $purpleCl;
                }
            }

            .plan-buy-button {
                border-color: $purpleCl;
                background-color: $purpleCl;

                &:hover {
                    border-color: $purpleCl;
                }
            }
        }

        .plan-main-info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            .plan-free-trail {
                width: 50%;
                background-color: $black;
                padding: 3px auto;
                font-size: 13px;
                font-weight: 500;
                color: #fff;
                text-align: center;
                border-radius: 8px;
                animation: moveFreeTrail 1.5s infinite alternate;
            }

            @keyframes moveFreeTrail {
                0% {
                    transform: rotateZ(-2deg);
                }
                100% {
                    transform: rotateZ(2deg);
                }
            }
        }
        .plan-text {
            font-size: $mediumFS;
            font-weight: 700;
            color: $black;
        }

        .plan-name {
            font-size: $midLarge;
            font-weight: 700;
            color: $black;
            width: 100%;
        }

        .plan-price {
            margin: 24px 0 12px 0;
            display: flex;
            align-items: center;
            h1 {
                font-size: 34px;
                font-weight: 700;
                color: $black;
            }

            h2 {
                font-size: $mediumFS;
                font-weight: 500;
                color: $gray2Cl;
                margin-left: 10px;
                margin-top: 13px;
            }
        }

        .plan-description {
            padding: 12px 0;
            margin-bottom: 12px;
            font-size: $smallFS;
            font-weight: 500;
            border-top: 1px solid #ebebf5;
            border-bottom: 1px solid #ebebf5;
            color: $black;
            line-height: 16px;
        }

        .plan-bulletpoint {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            h1 {
                margin-left: 8px;
                font-size: 13px;
                font-weight: 700;
                color: $black;
            }
        }

        .plan-buy-button {
            text-transform: uppercase;
            letter-spacing: 1px;
            position: absolute;
            bottom: 24px;
            left: 24px;
            right: 24px;
            border: 2px solid $black;
            border-radius: 29px;
            background-color: $black;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-weight: 700;
            color: #fff;
            padding: 8px 0;
            cursor: pointer;
            transform: scale(1);
            transition: all 0.5s ease;
            &:hover {
                letter-spacing: 3px;
                transform: scale(1.05);
                border-radius: 8px;
                background-color: white;
                border: 2px solid $black;
                color: $black;
                transition: all 0.5s ease;
            }
        }
    }
}

.features-container {
    margin-top: 24px;

    .features-row {
        display: grid;
        grid-template-columns: 80% 20%;
        align-items: center;

        .feature-details {
            h1 {
                font-size: $bigFS;
                font-weight: 700;
                width: 100%;
            }

            h2 {
                font-size: 13px;
                font-weight: 500;
                color: $gray2Cl;
            }
        }
        .features-plans {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
            h1 {
                font-size: $bigFS;
                font-weight: 700;
                color: $black;

                &.green {
                    color: $greenCl;
                }

                &.purple {
                    color: $purpleCl;
                }
            }
        }
    }
}

.footer-container {
    padding: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.footer {
    .ant-pagination-prev,
    .ant-pagination-next {
        button {
            border: none;
            background-color: transparent;
        }
    }

    li {
        border: none !important;
        margin: 0 10px;
        background-color: transparent;
        border-radius: 7px;

        &.ant-pagination-item-active {
            background-color: $purpleCl !important;

            a {
                color: white;
            }
        }

        &:hover {
            background-color: white;
            box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
        }

        a {
            color: $gray3Cl;
            font-size: $mediumFS;
            line-height: 32px;
        }
    }

    .ant-select-selector {
        border: none !important;
        background-color: transparent;
        border-radius: 7px;
        color: $gray3Cl;
        font-size: $mediumFS;
        line-height: 32px;
    }

    .ant-pagination-options {
        display: none;
    }
}

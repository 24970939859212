.login-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f4f4;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  .svg-container {
    height: 100%;
    position: absolute;

    &.left {
      max-width: 289px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      left: 0;
    }

    &.right {
      max-width: 394px;
      right: 0;
    }
  }

  .withEmail {
    font-size: $smallFS;
    font-weight: 500;
    color: $gray3Cl;
  }

  .inner-container {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    min-width: 320px;
    z-index: 99;
    text-align: center;
  }

  .or-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .line {
      width: 43%;
      height: 1px;
      border: 1px solid $gray2Cl;
    }

    h1 {
      font-size: $mediumFS;
      font-weight: 500;
      color: $gray2Cl;
    }
  }

  .footer-login {
    position: absolute;
    bottom: 30px;
    width: 202px;
    height: 48px;
  }
}
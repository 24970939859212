.guestlist-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;

    h1 {
        font-size: $smallFS;
        font-weight: 700;
    }

    .guestlist-date {
        width: 45px;
        background-color: #fff;
        text-align: center;
        border-radius: 5px;
        margin: 0 2px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 5px;

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
            -webkit-appearance: none !important;
            margin: 0;
        }

        input[type='number'] {
            -moz-appearance: textfield;
        }

        input {
            border: none !important;
            padding: 0;
            box-shadow: none;
            width: 100% !important;
            text-align: center;
            border-color: #fff !important;
        }
    }
}

.guestlist-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .guestlist-input {
        &.midwidth {
            width: 48%;
        }
    }
}

.guestlist-modal {
    .ant-modal-content {
        max-width: 100% !important;
    }
}

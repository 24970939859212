.sidebar-container {
  max-width: 360px;
  padding: 50px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.main-content-container {
  background: #F5F5F8;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  padding-left: 360px;
  padding-right: 350px;
}

@media only screen and (max-width: 1650px) {

  .main-content-container {
    padding-left: 155px;
  }

  .sidebar-container {
    max-width: 155px;
    padding: 40px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .sidebar-menu{
      padding: 16px;
      padding-top: 42px;


      .sidebar-logo {
        .lg {
          display: none;
        }
        .sm {
          display: block;
        }
      }

      .profile{
        .ant-avatar-lg{
          width: 40px;
          height: 40px;
        }

        .profile-info{
          display: none;
        }

        .profile-dropdown {
          max-width: 170px;
          width: 100vw;
        }
      }

      .link-text {
        display: none;
      }
      .nav-links a, .nav-links button {
        justify-content: center;
      }
    }


    .language-switch {
      flex-direction: column;
    }
  }
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  background-color: $neutralV800;
  border-radius: 24px;
  height: 100%;
  padding: 42px;
  color: $primaryP50;

  .sidebar-logo {
    display: flex;
    flex-shrink: 0;
    justify-content: center;

    .sm {
      display: none;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    max-height: 200px;
    flex-grow: 1;

    .profile-inner {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &:hover {
      cursor: pointer;
    }

    .ant-avatar-lg {
      width: 50px;
      height: 50px;
    }

    h1 {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      display: flex;
      gap: 8px;
      align-items: center;
    }

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      color: #A7A6AC;
    }


    .profile-dropdown {
      left: 0;
      bottom: -16px;
      z-index: 10;
      background: #23202F;
      border-radius: 6px;
      transform: translateY(100%);
      overflow: hidden;

      .pages-container {
        width: 100%;
        overflow: visible;
        height: 100%;

        .pages-row {
          border-color: #34313F;

          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 22px;

          display: flex;
          align-items: center;
          color: #F3EDFB;
          border-radius: 6px;
          transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

          &:hover {
            background-color: #CEB4EE;
            color: #34313F;
            cursor: pointer;
          }

          .ant-avatar-sm {
            position: relative;
            overflow: hidden;
          }
        }
      }
    }
  }

  .nav-menu {
    flex-grow: 1;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    gap: 9px;

    button {
      -webkit-appearance: none;
      background-color: transparent;
      border: none;
    }

    a, button {
      display: flex;
      gap: 19px;
      align-items: center;
      color: $primaryP50;
      padding: 6px;
      border-radius: 6px;


      &:hover {
        background-color: #CEB4EE;
        color: #34313F;
      }

      .icon {
        display: flex;
        width: 23px;
        height: 18px;

        &.icon-subscription, &.icon-logout {
          svg {
            path {
              fill: none;
            }
          }
        }

        svg {
          width: 100%;
          height: 100%;
          path {
            stroke: currentColor;
            fill: currentColor;
          }
        }
      }

      &.active {
        color: $purpleCl
      }
    }
  }

  .language-switch {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    color: $gray3Cl;

    > div {
      cursor: pointer;

      .active {
        color: $purpleCl
      }
    }
  }
}


.sidebar-right {
  left: unset;
  right: 0;
  background-color: #ffffff;
  max-width: 310px;
  padding: 40px;
  overflow-y: auto;


  &::-webkit-scrollbar {
    width: 9px;
    height: 7px;
    border-radius: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.12);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 10px;
  }
}


.sidebar-card {
  background: #FAFAFA;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 26px;

  .sidebar-card-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #34313F;
  }

  .sidebar-card-content {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #A7A6AC;

    .card-count {
      display: flex;
      font-weight: 700;
      font-size: 34px;
      line-height: 53px;
      color: #34313F;
      gap: 8px;
      margin-top: 15px;

      .card-count-icon {
        color: $purpleCl;
        height: 26px;
        width: 26px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.contact-card {
    .profile-name {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #34313F;
    }

    .profile-role {
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      color: #A7A6AC;
    }

    .profile-image {
      height: 55px;
      width: 55px;
      border-radius: 100%;
      margin-right: 8px;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .contact-info {
      margin-bottom: 11px;
    }

    .contact-options > div {
      margin-top: 9px;

      a {
        font-weight: 600;
        font-size: 10px;
        line-height: 18px;
        letter-spacing: -0.03em;
        color: #A7A6AC;
        margin-left: 8px;
      }
    }
  }


  &.contract-card{
    background-color: $purpleCl;
    color: #ffffff;

    h3 {
      margin-top: 13px;
      font-weight: 800;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: -0.01em;
      color: #F3EDFB;
    }

    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #F3EDFB;
    }

    button.ant-btn.ant-btn-default, a.ant-btn.ant-btn-default {
      background-color: #F3EDFB;
      color: $purpleCl;
      border-color: #e1d2f5;

      &:active, &.ant-dropdown-open {
        border-color: darken($purpleCl, 20%);
      }

      &:focus {
        border-color: darken($purpleCl, 10%);
      }

      &:hover {
        border-color: #e1d2f5;
        background-color: darken(#F3EDFB, 5%);
        color: $purpleCl;

        &:active, &.ant-dropdown-open {
          border-color: darken($purpleCl, 20%);
        }
      }
    }
  }

  &.weather-card {
    .sidebar-card-title {
      margin-bottom: 12px;

      .weather-icon {
        height: 30px;
        display: flex;
        margin-bottom: 6px;

        svg {
          width: auto;
          height: 100%;
        }
      }

      .weather-city {
        color: #A7A6AC;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;

        b {
          color: #000000;
          font-weight: 700;
          font-size: 14px;
          line-height: 15px;
        }
      }

      .weather-temp {
        font-size: 34px;
        letter-spacing: -0.02em;
        font-weight: 700;
      }
    }

    .sidebar-card-content {
      font-weight: 600;
      font-size: 10px;
      display: grid;
      grid-template-columns: repeat(6, minmax(0, 1fr));
      text-align: center;
      margin: 0 -8px;

      .weather-icon {
        width: 25px;
        height: 30px;
        display: flex;
        margin: 0 auto;

        svg {
          width: auto;
          height: 100%;
        }
      }

      .weather-temp {
        font-size: 12px;
        color: #080415;
      }
    }
  }

  &.app-card {
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      margin-bottom: 5px;
    }

    button, a.ant-btn {
      margin-top: 5px;
    }
  }

  &.mails-card {
    h3 {
      margin-bottom: 13px;
    }

    p {
      margin-bottom: 0;
    }

    button, a.ant-btn {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      &:first-child {
        svg {
          width: 13px;
        }
      }

      svg {
        width: 16px;
      }
    }
  }

  &.quick-add {
    background-color: #F3EDFB;

    .sidebar-card-title {
      margin-bottom: 25px;
    }

    .error-text {
      font-size: 12px;
    }

    .sidebar-card-content {
      input.custom-input {
        border: none !important;
        height: 40px;
        background-color: #FFFFFF;
        margin-bottom: 0;

        &:first-child{
          margin-bottom: 6px;
        }
      }
    }
  }

  button, a.ant-btn {
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }

  a.ant-btn {
    padding-top: 12px !important;
    line-height: 1;
  }
}

.header-container {
    width: 100%;
    background-color: white;
    position: absolute;
    box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
    z-index: 100;
    height: 80px;

    .header {
        margin: 0 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;

        .sidenav-close {
            display: none;
        }
    }
}
.cell {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    &.profile {
        text-align: right;
        cursor: pointer;
        position: relative;
        h1 {
            margin-right: 10px;
            color: #000;
        }
    }

    &.language {
        margin-right: 15px;
        h1 {
            margin: 0;
            margin-right: 10px;

            &.gray {
                color: $gray2Cl;
            }
        }
    }

    h1,
    h2 {
        font-size: $mediumFS;
        font-weight: 500;
        text-transform: capitalize;
        margin: 0%;
        margin-left: 35px;
        cursor: pointer;
        color: $gray2Cl;
        display: flex;
        align-items: center;

        span {
            margin-right: 10px;
            top: 0.5px;
            display: flex;
            align-items: center;
        }

        &.active {
            color: $purpleCl;
        }
    }

    h1 {
        font-weight: 700;
    }

    h2 {
        padding: 10px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $grayCl;
            border-radius: 5px;
        }
    }

    h3 {
        font-size: $smallFS;
        font-weight: 700;
        color: $gray2Cl;
        margin: 0px 10px 0 0;
        letter-spacing: 1.5px;
    }
}

.profile-dropdown {
    position: absolute;
    background-color: white;
    right: 0;
    box-shadow: 0 2px 10px rgb(33 43 54 / 8%);
    display: flex;
    flex-direction: column;
    transform: translateY(150px);

    .pages-container {
        height: 150px;
        overflow-y: scroll;
        width: 200px;

        .pages-row {
            display: flex;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid $grayCl;

            &:last-child {
                border-bottom: 0;
            }

            &:hover {
                background-color: #fafafa;
                transition: all 0.5 ease;
            }

            .page-avatar-container {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .page-avatar {
                    width: 28px;
                    height: 28px;
                    border-radius: 50px;
                    background-color: $grayCl;
                    overflow: hidden;
                    position: relative;
                }
            }

            .page-info {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
                flex: 4;
                h1,
                h2 {
                    margin: 0;
                    padding: 0;
                }

                h1 {
                    width: 90%;
                    text-align: left;
                }

                h2 {
                    font-size: $smallFS;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .logout-button {
        border-top: 1px solid $grayCl;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            margin: 0;
            padding: 0;
            font-size: $smallFS;
            color: $purpleCl;
            margin-right: 5px;
            text-transform: uppercase;
            letter-spacing: 1.2px;
        }
    }
}

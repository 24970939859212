.data-container {
    width: 100%;
    left: 0;
    margin-top: 25px;
    overflow-x: hidden;

    &.collapsed {
        position: relative;
        width: 100%;
        height: fit-content;
        margin-top: 0;
    }

    .data-row {
        width: 100%;
        padding: 18px 24px;
        margin: 10px 0;
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        grid-template-rows: auto;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 10px;

        &.account-venue {
            display: flex;

            .account-permissions {
                h1 {
                    font-size: $bigFS;
                    font-weight: 700;
                }
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
            }

            .account-delete {
                display: none;
            }
        }

        &.guests {
            .part1 {
                h1,
                h2 {
                    max-height: 53px;
                    max-width: 100%;
                    word-wrap: unset;
                    white-space: unset;
                    overflow: unset;
                    text-overflow: unset;
                }
            }
        }

        &.guests-small {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .part1 {
                padding: 0 0 0 16px;
            }

            .three-dot {
                padding: 0 16px 0 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }

        .part1,
        .part2,
        .part3 {
            display: flex;
            align-items: center;

            h1,
            h2,
            h3 {
                margin: 0%;
            }

            h1 {
                font-size: $bigFS;
                font-weight: 700;
            }

            h2 {
                font-size: $mediumFS;
                font-weight: 500;
                color: $gray2Cl;
            }

            h3 {
                font-size: $smallFS;
                font-weight: 500;
                color: $gray3Cl;
            }

            .data {
                display: flex;
                align-items: center;
            }
        }

        .part1 {
            h1,
            h2 {
                max-height: 53px;
                max-width: 300px;
                word-wrap: break-word;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &.guestList {
                // width: 100%;
                h1 {
                    max-width: 650px;
                }
            }

            .guests-status {
                display: flex;
                align-items: center;

                .change-status {
                    margin-left: 10px;
                }

                .crew-pill {
                    border-radius: 5px;
                    margin-right: 5px;
                }
            }
        }

        .part2 {
            display: flex;
            flex-wrap: wrap;

            * {
                font-size: $smallFS;
                font-weight: 700;
                padding: 6px 10px;
                color: $gray3Cl;
                background-color: $grayCl;
                margin-right: 4px;
                margin-bottom: 4px;
                border-radius: 8px;
            }
        }

        .three-dot {
            display: none;
        }

        .part3 {
            .crew-permissions {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .crew-permissions-pill {
                    border-radius: 8px;
                    background-color: $grayCl;
                    padding: 6px 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 4px;
                    margin-bottom: 4px;
                    h2 {
                        color: $gray3Cl;
                        font-size: $smallFS;
                    }
                }
            }
        }

        &.pages {
            grid-template-columns: 1fr 1fr auto auto;
            position: relative;

            &.account-venue {
                .account-delete {
                    display: flex;
                    align-items: center;
                }
            }

            .three-dot {
                position: relative;
                display: flex;
                margin-left: 10px;
                transition: transform 0.2s ease;
                align-content: center;

                .three-dot-actions {
                    display: none;
                }

                .three-dot-icons {
                    align-self: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:hover {
                    transform: scale(1.2);

                    .three-dot-actions {
                        display: block;
                        position: absolute;
                        background-color: #fff;
                        min-width: 180px;
                        box-shadow: 0 2px 10px rgb(33 43 54 / 8%);
                        border-radius: 5px;
                        z-index: 999999;
                        right: 5%;
                        padding: 8px 4px;

                        .actions-row {
                            display: flex;
                            margin-bottom: 5px;
                            align-items: center;
                            padding: 6px 10px;
                            border-radius: 5px;
                            transition: background-color 0.3s ease;

                            &:hover {
                                background-color: #F5F5F8;
                            }

                            h1 {
                                font-size: $smallFS;
                                color: #000;
                                margin-left: 10px;
                            }

                            &.red {
                                margin: 0%;

                                h1 {
                                    color: $redCl;
                                }
                            }

                            &.green {
                                margin: 0%;

                                h1 {
                                    color: $greenCl;
                                }
                            }

                            &.disabled {
                                box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
                                background-color: $grayCl;
                                pointer-events: none;
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }

        &:hover {
            background-color: white;
            box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
            grid-template-columns: 1fr 1fr auto auto;
            position: relative;

            &.account-venue {
                .account-delete {
                    display: flex;
                    align-items: center;
                }
            }

            .three-dot {
                position: relative;
                display: flex;
                margin-left: 10px;
                transition: transform 0.2s ease;
                align-content: center;
                z-index: 10;

                .three-dot-actions {
                    display: none;
                }

                .three-dot-icons {
                    align-self: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:hover {
                    transform: scale(1.2);

                    .three-dot-actions {
                        display: block;
                        position: absolute;
                        background-color: #fff;
                        min-width: 180px;
                        box-shadow: 0 2px 10px rgb(33 43 54 / 8%);
                        border-radius: 5px;
                        z-index: 999999;
                        right: 5%;
                        padding: 8px 4px;

                        .actions-row {
                            display: flex;
                            margin-bottom: 5px;
                            align-items: center;
                            padding: 6px 10px;
                            border-radius: 5px;
                            transition: background-color 0.3s ease;

                            &:hover {
                                background-color: #F5F5F8;
                            }

                            h1 {
                                font-size: $smallFS;
                                color: #000;
                                margin-left: 10px;
                            }

                            &.red {
                                margin: 0%;

                                h1 {
                                    color: $redCl;
                                }
                            }

                            &.green {
                                margin: 0%;

                                h1 {
                                    color: $greenCl;
                                }
                            }

                            &.disabled {
                                box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
                                background-color: $grayCl;
                                pointer-events: none;
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }
    }
}

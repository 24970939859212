$purpleCl: #8849d6;
$orangeCl: #fec400;
$gray: #F5F5F8;
$grayCl: #e7e9ed;
$gray2Cl: #9697a1;
$gray3Cl: #575962;
$redCl: #ff427e;
$greenCl: #03dac5;
$black: #171a23;
$blueCl: #0399da;
$yellowCl: #fec400;
$secondOrangeCl: #ff8500;
$neutralV800: #141121;
$primaryP50: #F3EDFB;

.custom-button {
    width: 100%;
    margin: 26px 0 16px 0;
    padding: 11px 0;
    height: fit-content;
    background-color: #000;
    color: white;
    font-size: $mediumFS;
    font-weight: bold;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease;

    &:hover {
        opacity: 0.8;
        background-color: #000;
        color: white;
        border-color: $grayCl;
    }

    &:disabled {
        pointer-events: none;
    }
}

.pages > .ant-upload.ant-upload-select-picture-card {
    border: none;
    height: 150px;
    width: 150px;
}

.onboarding > .ant-upload.ant-upload-select-picture-card {
    border: none;
    height: 120px;
    margin-bottom: 16px;
    width: 120px;
}

.custom-input {
    width: 100%;
    margin-bottom: 12px;
    padding: 4px 11px;
    height: 40px;
    font-size: $mediumFS;
    background-color: #f5f5f8;
    border-radius: 5px;
    border: 1px solid #8849D6 !important;
    transition: border 0.3s ease;

    &.white-background {
        background-color: #fff;
    }

    &::placeholder {
        color: #9697a1;
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        box-shadow: none;
    }

    &:disabled {
        pointer-events: none;
    }

    &.with-error {
        border: 2px solid $redCl !important;
        transition: border 0.3s ease;
    }
}

.error-text {
    font-size: $mediumFS;
    font-weight: 500;
    color: $redCl;
    margin-bottom: 8px;
    align-self: flex-start;
    max-width: 320px;
    text-align: left;
    animation: fadeInText 0.5s ease-in both;
}

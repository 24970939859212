.create-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 10px;

    &:hover {
        background-color: $grayCl;

        input,
        textarea {
            background-color: $grayCl;
        }

        h1 {
            color: $purpleCl;
        }

        .ant-select,
        .ant-select-single,
        .ant-select-show-arrow {
            background-color: transparent !important;

            .ant-select-selector {
                background-color: transparent !important;

                .ant-select-selection-search-input {
                    position: relative;
                    left: -15px;
                }
            }
        }
    }

    input,
    textarea {
        margin: 0 10px;
        border: none;
        cursor: pointer;
        font-size: $bigFS;
        font-weight: 500;
        border-radius: 0px;
        padding: 0;
        margin-bottom: 4px;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            border-bottom: 1px solid rgb(136 73 214 / 20%);
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        &::placeholder {
            font-size: $mediumFS;
            font-weight: 500;
            color: $gray2Cl;
        }

        &.big {
            margin-left: 0;

            &::placeholder {
                font-size: $bigFS;
                font-weight: 700;
                color: #000;
            }
        }
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    .placeholder {
        font-size: $mediumFS;
        font-weight: 500;
        color: $gray2Cl;
        margin-left: 10px;
        animation: fadeInText 0.3s ease-in both;
    }

    .mainText {
        font-size: $bigFS;
        color: $gray3Cl;
        font-weight: 500;
    }

    h1 {
        font-size: $smallFS;
        font-weight: 700;
        color: $gray2Cl;
        min-width: 10%;
        text-align: right;
    }

    .attending {
        width: 100%;
        margin-left: 0px;
        display: flex;
        align-items: center;

        h1 {
            font-size: $smallFS;
            font-weight: 500;
        }
    }

    // antd design
    .ant-select-selection-item {
        font-size: $mediumFS;
        font-weight: 500;
        color: $gray2Cl;
        padding: 0%;

        &:focus {
            box-shadow: none;
            border: none;
        }
    }

    .ant-select,
    .ant-select-single,
    .ant-select-show-arrow,
    .ant-select-open,
    .ant-selecy-focused {
        background-color: transparent !important;

        .ant-select-arrow {
            display: none;
        }

        .ant-select-selector {
            padding: 0;
            border: none;

            .ant-select-selection-placeholder {
                font-size: $mediumFS;
                font-weight: 500;
                color: $gray2Cl;
            }

            &:hover {
                background-color: transparent !important;
            }
        }

        .ant-select-focused:not(.ant-select-disabled),
        .ant-select:not(.ant-select-customize-input),
        .ant-select-selector {
            border-color: none !important;
            box-shadow: none !important;
        }
    }

    // antd design end
}

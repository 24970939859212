.crew-main-card {
    border-radius: 12px;
    background: linear-gradient(90deg, #BB86FC 0%, #8849D6 100%);
    box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
    padding: 24px;
    margin: 24px 0;

    .crew-card-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &.mb {
            margin-bottom: 24px;
        }

        .crew-card-small-text {
            font-size: $smallFS;
            font-weight: 700;
            letter-spacing: 0.6px;
            color: white;
            text-transform: uppercase;

            &.light {
                font-weight: 400;
            }

            &.ml {
                margin-left: 8px;
            }
        }

        .crew-card-big-text {
            font-size: $midLarge;
            letter-spacing: 0.3px;
            font-weight: bold;
            color: white;
            text-transform: capitalize;

            &.large {
                font-size: $exLargeFS;
            }
        }

        .crew-card-usage {
            border-left: 1px solid rgba(255, 255, 255, 0.3);
            padding: 4px 0 4px 22px;
        }

        .crew-card-upgrade {
            padding: 8px 16px;
            background-color: $purpleCl;
            border-radius: 20px;
            margin-right: 24px;
            cursor: pointer;
            transition: all 0.5s ease;

            &:hover {
                transform: scale(1.05);
                transition: all 0.5s ease;
            }
        }

        .crew-card-progress-bar {
            width: 100%;
            height: 32px;
            border-radius: 20px;
            border: 2px solid white;
            overflow: hidden;
            background-color: rgba(255, 255, 255, 0.3);

            .crew-card-progress-bar-inner {
                height: 100%;
                width: 0%;
                background-color: white;
            }
        }
    }
}

.crew-member-container {
    background-color: #fff;
    padding: 12px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        align-items: center;

        &.pointer {
            cursor: pointer;
        }

        &.border {
            border-bottom: 1px solid #f5f5f8;
            padding: 12px 0;
        }

        .big-text {
            font-size: $largeFS;
            font-weight: 700;
            letter-spacing: 0.5px;
        }

        .second-big-text {
            font-size: $bigFS;
            font-weight: 500;
        }

        .gray-text {
            font-size: $mediumFS;
            font-weight: 500;
            color: $gray2Cl;
        }
    }
    .header {
        justify-content: space-between;
        margin-bottom: 25px;

        h1 {
            font-size: $smallFS;
            letter-spacing: 0.6px;
            color: $purpleCl;
            font-weight: 700;
            text-transform: uppercase;
            margin-left: 4px;
        }
    }

    .button {
        padding: 11px 0;
        width: 45%;
        background-color: $purpleCl;
        font-size: $mediumFS;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;

        &.gray {
            background-color: #f5f5f8;
            color: $redCl;
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

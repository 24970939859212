.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.items-center {
  align-content: center;
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.w-full {
  width: 100%;
}

.mb-0 {
  margin-bottom: 0;
}

.card-loading {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.page-crowd {
  header {
    .header-content {
      border-bottom: 1px solid $grayCl;
      padding-bottom: 20px;
    }

    .header-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filter-group {
        display: flex;
        gap: 6px;
      }
      .filter-options {
        margin-top: 16px;
      }
    }
  }


  main {
    margin-top: 35px;
    padding-bottom: 50px;
  }
}

.data-card {
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;

  .data-card-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #34313F;
    display: flex;
    justify-content: space-between;

    > span {
      font-weight: 600;
      color: #504D59;
      font-size: 13px;
    }
  }

  .data-card-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.data-card.people-card {
  .data-card-inner {
    padding: 10px 35px;
    display: flex;
    flex-direction: row;
    gap: 35px;
  }
}

.counter-card {
  background-color: $purpleCl;
  border-radius: 12px;
  overflow: hidden;
  width: 266px;
  height: 266px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: $primaryP50;
  flex-shrink: 0;
  position: relative;

  &:after {
    content: '';
    background-image: url("/images/bg-purple-bubbles.png");
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
  }

  .counter-card-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
  }

  .counter-card-value {
    font-size: 80px;
    color: #fff;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.02em;

    &.small {
      font-size: 55px;
      line-height: 80px;
    }
  }

  .counter-card-delta {
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    display: flex;
    align-items: center;
    gap: 6px;

    .icon {
      color: $greenCl;
      width: 14px;
      height: 14px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.data-card-list {
  display: flex;
  gap: 22px;
  margin-top: 35px;
  overflow-x: scroll;
  padding-bottom: 15px;

  &::-webkit-scrollbar {
    width: 9px;
    height: 7px;
    border-radius: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.12);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 10px;
  }

  .data-card {
    width: 286px;
    height: 330px;
    flex-shrink: 0;
  }
}


.pie-card {
  .data-card-inner {
    padding: 0 20px;
    display: flex;
    gap: 10px;
  }

  .chart-container {
    height: 150px;
    width: 100%;
    position: relative;
    flex-shrink: 0;

    .recharts-responsive-container {
      margin-left: 0;
    }
  }

  .chart-info {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
  }

  .chart-info-group {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &:first-child {
      .chart-info-item:first-child {
        color: $purpleCl;
      }

      .chart-info-item:last-child {
        color: $greenCl;
      }
    }

    &:last-child {
      .chart-info-item:first-child {
        color: $redCl
      }

      .chart-info-item:last-child {
        color: $yellowCl
      }
    }
  }

  .chart-info-item-value {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .chart-info-item-icon {
    width: 18px;
    height: 15px;
    display: flex;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .chart-info-item-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #A7A6AC;
  }

  .chart-total {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 1;

    .total-label {
      font-weight: 600;
      font-size: 13px;
      line-height: 17px;
      color: #A7A6AC;
    }
  }
}

.people-chart {
  height: 266px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .chart-container {
    width: 100%;
    height: 100%;
  }

  .people-chart-title {
    font-weight: 700;
    font-size: 14px;
    color: #97969D;
  }
}

.recharts-responsive-container {
  margin-left: -25px;


  .recharts-cartesian-axis {
    > line {
      stroke: transparent;
    }
  }

  .recharts-cartesian-axis-tick {
    line {
      stroke: transparent;
    }

    text {
      fill: #532D83;
      font-size: 12px;
    }
  }
}

.progress-card {
  height: 100%;
  display: flex;

  .data-card-inner {
    padding: 0 20px;
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .progress-list {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &::-webkit-scrollbar {
      width: 9px;
      height: 7px;
      border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.12);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.12);
      border-radius: 10px;
    }
  }

  .progress-container {
    display: flex;
    flex-direction: column;
  }

  .progress-bar {
    margin-top: 5px;
    width: 100%;
    height: 5px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #E6FBF9;

    .progress-bar-inner {
      background-color: #03DAC5;
      height: 5px;
      border-radius: 6px;
    }
  }
}

.data-card.bars-card {
  width: 440px;
  display: flex;

  .data-card-inner {
    padding: 0 20px;
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .chart-container {
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: 10px;
  }
}


.table-card {
  margin-top: 30px;

  .data-card-inner {
    padding: 0 30px;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .table-title {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 15px;
    color: #34313F;
  }

  .table-total {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #A7A6AC;
  }

  .table-content > .table-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 300px;
    align-items: center;
    gap: 6px;

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 23px;
      color: #CEB4EE;
    }
  }

  .table-content {
    > div {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .table-content-inner {
      max-height: 370px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 9px;
        height: 7px;
        border-radius: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.12);
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.12);
        border-radius: 10px;
      }
    }

    .table-content-header {
      font-weight: 700;
      font-size: 14px;
      padding: 0 8px 16px 8px;
      border-bottom: 1px solid #EBEBEC;
    }
  }

  .table-content-item {
    padding: 16px 8px;
    display: flex;
    align-items: center;

    &.user-gender, &.user-origin {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span:first-child {
        font-weight: 700;
        font-size: 14px;
        line-height: 23px;
        color: #34313F;
      }

      span:not(:first-child) {
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        color: #A7A6AC;
      }
    }

    .user-avatar {
      width: 50px;
      height: 50px;
      border-radius: 12px;
      overflow: hidden;
      margin-right: 20px;

      > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .user-name {
      font-weight: 700;
      font-size: 14px;
      color: #34313F;
      line-height: 23px;
    }

    .user-checkin {
      font-weight: 600;
      font-size: 13px;
      color: #A7A6AC;
      line-height: 17px;
    }
  }
}


/**
* Filter Modal
*/

.modal-container {
  width: 100% !important;
  max-width: 750px;

  .ant-modal-content {
    background-color: #FAFAFA;
  }

  &.template-modal {
    .title-container{
      margin-bottom: 20px;
    }

    .title {
      margin-bottom: 0;
      margin-right: 10px;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: -0.01em;
    }

    .filter-footer {
      border-top: 0;
    }
  }

  .filter-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    border-top: 3px solid #F3EDFB;
    gap: 5px;
  }

  .filter-body {
    .filter-item {
      padding: 26px 20px;
      display: flex;
      gap: 25px;
      align-items: center;

      .filter-title {
        flex-shrink: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #EBEBEC;
      }

      .filter-options {
        width: 100%;
        flex-grow: 1;
        row-gap: 5px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}


.ant-btn {
  padding: 12px 13px;
  height: 34px;
  display: flex;
  border-radius: 5px;

  > * {
    font-weight: 500;
    font-size: 12px;
    line-height: .8;
    align-items: center;
    display: flex;
  }

  .anticon {
    height: 10px;
    width: 10px;


    svg {
      height: 100%;
      width: 100%;
    }
  }

  &.ant-btn.ant-btn-default.btn-icon {
    padding: 6px  8px;
    background-color: #F5F5F8;
    color: #34313F;
    border: none;

    &:hover {
      background-color: darken(#F5F5F8, 5%);
    }

    &:active, &:focus {
      background-color: darken(#F5F5F8, 10%);
    }


    .anticon {
      height: 16px;
      width: 16px;
      color: #34313F;


      svg {
        height: 100%;
        width: 100%;
      }
    }
  }

  &.btn-small {
    padding: 5.5px 6px;
    height: 21px;
    font-weight: 500;
    font-size: 12px;
  }

  &.ant-btn-icon-only {
    align-items: center;
    justify-content: center;
  }

  &.ant-btn-default {
    background-color: $purpleCl;
    color: #ffffff;
    border: 1px solid $purpleCl;

    &.inactive, &:disabled, &:disabled:active, &:disabled:hover {
      background-color: #F3EDFB;
      color: #BA95E7;
      border-color: #F3EDFB;
    }

    &:active, &.ant-dropdown-open {
      border-color: darken($purpleCl, 20%);
    }

    &:focus {
      border-color: darken($purpleCl, 10%);
    }

    &:hover {
      background-color: lighten($purpleCl, 10%);
      color: #ffffff;

      &:active, &.ant-dropdown-open {
        border-color: darken($purpleCl, 20%);
      }
    }
  }

  &.btn-secondary {
    background-color: #F3EDFB;
    color: $purpleCl;
    border-color: $purpleCl;

    &:active, &.ant-dropdown-open {
      border-color: darken($purpleCl, 20%);
    }

    &:focus {
      border-color: darken($purpleCl, 10%);
    }

    &:hover {
      border-color: #e1d2f5;
      background-color: darken(#F3EDFB, 5%);
      color: $purpleCl;

      &:active, &.ant-dropdown-open {
        border-color: darken($purpleCl, 20%);
      }
    }
  }
}


.filter-options .ant-select {
  .ant-select-selector {
    border-radius: 6px;
  }

  &:hover {
    .ant-select-selector {
      border-color: $purpleCl;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled) {
    .ant-select-selector {
      border-color: $purpleCl;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #F3EDFB;
  color: $purpleCl;

  .ant-select-item-option-state {
    color: $purpleCl;
  }
}

.ant-select-multiple .ant-select-selection-item, .ant-tag.ant-tag-checkable {
  background: #F5F5F6;
  border-radius: 6px;
  border: 1px solid #F5F5F6;
  font-size: 12px;
  display: flex;
  align-items: center;

  .ant-select-selection-item-content {
    font-size: 12px;
    padding-left: 7px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-select-selection-item-remove {
    display: flex;
    vertical-align: unset;
    height: 10px;
  }

  &.ant-tag-checkable-checked {
    background: #F3EDFB;
    border: 1px solid #CEB4EE;
    border-radius: 6px;
    color: $purpleCl;
  }

  &:hover {
    color: $purpleCl;
  }
}

.ant-slider.ant-slider-horizontal {
  width: 100%;

  .ant-slider-rail {
    background: #E6FBF9;
    border-radius: 10px;
    height: 7px;
  }

  .ant-slider-track {
    background: #6DEADD;
    border-radius: 10px;
    height: 7px;
  }

  .ant-slider-handle {
    background: #03DAC5;
    height: 14px;
    width: 14px;
    border: none;
    margin-top: -4px;
  }

  .ant-slider-dot {
    display: none;
  }
}


.monitoring-card {
  background: #080415;
  position: relative;
  padding: 0;

  &:before {
    content: '';
    background-image: url('/images/bg-monitoring.png');
    width: 100%;
    height: 100%;
    position: absolute;    z-index: 0;
    background-position: right center;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .data-card-inner {
    padding: 25px 32px;
    padding-bottom: 35px;
    position: relative;
    z-index: 1;

    .data-card-title {
      display: flex;
      justify-content: space-between;
      color: #ffffff;
      margin-bottom: 20px;
      align-items: center;
      font-size: 18px;
    }

    .monitor-cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      > div {
        background-color: #141121;
        border-radius: 12px;
        padding: 25px;
        width: 100%;
      }

      .people-chart {
        padding: 0;
        padding-top: 25px;
        padding-bottom: 14px;

        .people-chart-title {
          color: #F3EDFB;
          padding-left: 30px;
        }

        .recharts-responsive-container {
          margin-left: -25px;


          .recharts-cartesian-axis {
            > line {
              stroke: transparent;
            }
          }

          .recharts-cartesian-axis-tick {
            line {
              stroke: transparent;
            }

            text {
              fill: #DFDEE1;
              font-size: 12px;
            }
          }
        }
      }

      .counter-card {
        &:after {
          background-image: none;
        }
      }
    }
  }
}

.info-card {
  margin-top: 30px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/images/bg-membership-info.png');
    z-index: 0;
    background-position: right center;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .data-card-inner {
    padding: 0 31px;
    flex-direction: row;
    z-index: 10;
    position: relative;
  }

  .info-section {
    border-right: 1px solid #EBEBEC;
    margin-right: 42px;
    padding-right: 42px;
    display: flex;
    flex-direction: column;

    .info-title {
      font-weight: 700;
      font-size: 14px;
      color: #141121;
    }

    .info-subtitle {
      font-weight: 600;
      font-size: 10px;
      line-height: 18px;
      letter-spacing: -0.03em;
      color: #A7A6AC;
    }

    .info-content {
      &.gender {
        font-weight: 500;
        font-size: 12px;
        svg {
          display: inline-block;
          margin-right: 3px;
        }

        span {
          display: block;
        }
      }

      &.genres {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        font-size: 12px;
      }

      &.age {
        font-weight: 700;
        font-size: 38px;
        line-height: 1;
      }
    }
  }

  .info-action {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    padding-right: 75px;

    a {
      text-decoration: underline;

      &:hover {
        color: $purpleCl;
      }
    }
  }
}


.analytic-modal {
  width: 100% !important;
  max-width: 970px;

  .analytic-charts {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
    margin-top: 20px;

    .data-card {
      height: 330px;
      width: 100%;
    }
  }
}

.analytic-cards {
  display: flex;
  gap: 22px;
  margin-top: 35px;
  padding-bottom: 15px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 9px;
    height: 7px;
    border-radius: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.12);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 10px;
  }

  .data-card {
    width: 286px;
    height: 330px;
    flex-shrink: 0;
  }
}


div.ant-dropdown {
  z-index: 1001;
}

.ant-dropdown-menu.guest-list {
  .ant-dropdown-menu-item {
    &:hover  {
      background-color: #F3EDFB;
    }
    svg {
      width: 17px;
      height: 17px;
      color: $purpleCl;
      margin-right: 10px;
    }
  }



}

.copy-guest-list-modal {
  .modal-header {
    margin-bottom: 35px;

    h1 {
      margin-bottom: 0;
    }

    p {
      color: #A7A6AC;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
    }
  }

  .guest-list-list {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 9px;
      height: 7px;
      border-radius: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.12);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.12);
      border-radius: 10px;
    }


    .guest-list-item {
      padding: 12px 0;
      border-top: 1px solid #EBEBEC;

      &.guest-list-item-selected {
        .guest-list-item-inner {
          background-color: $purpleCl;

          .guest-list-item-name {
            color: #ffffff;
          }

          .guest-list-item-data {
            color: #ffffff;
          }
        }
      }

      .guest-list-item-inner {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 13px 24px 13px 13px;
        border-radius: 10px;
      }

      &:hover:not(.guest-list-item-selected) {
        .guest-list-item-inner {
          cursor: pointer;
          background-color: #F3EDFB;
        }
      }


      .guest-list-item-image {
        width: 60px;
        height: 50px;
        border-radius: 6px;
        overflow: hidden;

        img  {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .guest-list-item-info {
        flex-grow: 1;
      }

      .guest-list-item-name {
        font-weight: 700;
        font-size: 14px;
        line-height: 23px;
        color: #34313F;
      }

      .guest-list-item-data {
        font-weight: 600;
        font-size: 13px;
        line-height: 17px;
        color: #A7A6AC;
      }
    }
  }

  .guest-list-list-footer {
    padding-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}

@import 'fontsize';
@import 'colors';
@import 'inputs';
@import './pages/login.scss';
@import './components/header.scss';
@import './components/sidebar.scss';
@import './components/content.scss';
@import './components/footer.scss';
@import './components/data.scss';
@import './pages/createEvents.scss';
@import './components/create-inputs.scss';
@import './components/statescreens.scss';
@import './components/venue.scss';
@import './components/accounts.scss';
@import './components/guestlist-modal.scss';
@import './components/guestlist-details.scss';
@import './components/crew.scss';
@import './pages/plans.scss';
@import './pages/crowd.scss';

@font-face {
    font-family: 'Manrope';
    src: url('../../public/fonts/Manrope-Bold.otf');
    font-weight: 700;
}

@font-face {
    font-family: 'Manrope';
    src: url('../../public/fonts/Manrope-Regular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'Manrope';
    src: url('../../public/fonts/Manrope-Medium.otf');
    font-weight: 500;
}

html,
body {
    padding: 0;
    margin: 0;
    min-height: 100%;
    font-family: 'Manrope', sans-serif;
    button {
        cursor: pointer;
    }
}

// nprogress
#nprogress {
    .bar {
        background: $purpleCl !important;
        height: 3px !important;
    }

    .peg {
        box-shadow: 0 0 10px $purpleCl, 0 0 5px $purpleCl !important;
    }

    .spinner-icon {
        border-top-color: $purpleCl !important;
        border-left-color: $purpleCl !important;
    }
}

.dresscode-select {
    width: 182px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0px 0px rgba(6, 44, 82, 0.1), 0px 1px 3px rgba(64, 66, 69, 0.12), 0px 2px 16px rgba(33, 43, 54, 0.08);
    padding: 0 4px;

    .dresscode-select-option, .ant-select-item {
        align-items: center;
        margin-bottom: 4px;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        transition: all 0.5s ease;

        &:hover {
            background-color: #f5f5f8;
            transition: all 0.5s ease;
        }
    }
}

.ant-dropdown {
    width: 182px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 0px 0px rgba(6, 44, 82, 0.1), 0px 1px 3px rgba(64, 66, 69, 0.12), 0px 2px 16px rgba(33, 43, 54, 0.08);
    position: absolute;
    top: 0;
    right: 25px;
    top: 50px;
    z-index: 10;
    padding: 8px 4px;
    flex-direction: column;

    .ant-dropdown-menu {
        background: #fff;
        box-shadow: none;
        padding: 0;
    }

    .ant-dropdown-menu-item {
        align-items: center;
        margin-bottom: 4px;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 5px;
        transition: all 0.5s ease;

        &:hover {
            background-color: #f5f5f8;
            transition: all 0.5s ease;
        }
    }
}

.ant-collapse-item {
    margin-top: 28px;
}

.ant-collapse-header {
    padding: 8px 0 !important;
}

.ant-collapse-content-box {
    padding: 24px 0 !important;
}

.modal-container {
    // width: 25% !important;
    padding-bottom: 0;
    // WOLTER-2022-06-15 on Screens between 1200 and 1600 the width is to small with just 33%, so we increase it
    @media only screen and (min-width: 1199px) and (max-width: 1600px) {
        width: 50% !important;
    }
    .ant-modal-content {
        margin: 0;
        max-width: 100% !important;
    }
}

.add-guest-list-event-select {
    margin-bottom: 0px;
    width: 100%;
}
//  antd
.absolute-color-picker {
    position: absolute;
    right: 0;
    z-index: 100;
    transform: translateY(20%);
    &.lower {
        transform: translateY(55%);
    }

    &.higher {
        transform: translateY(-10%);
    }
}
.select-option {
    .ant-select-item-option-content {
        overflow: unset;
        white-space: break-spaces;
        text-overflow: unset;

        h1 {
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.ant-switch-checked {
    background-color: $purpleCl;
}

.ant-select-focused:not(.ant-select-disabled),
.ant-select:not(.ant-select-customize-input),
.ant-select-selector {
    border-color: none !important;
    box-shadow: none !important;
}

// WOLTER-2022-06-15 there is a white ugly overlay on in the select field that should not show up
.ant-select-selector {
    background-color: transparent !important;
}

.ant-upload {
    position: relative;

    span {
        width: 100% !important;
        height: 100% !important;
        position: relative;
    }
}

.ant-modal {
    &.venue-gallery {
        .ant-modal-content {
            max-width: 80%;
        }
      .ant-upload-list-item-actions {
        .anticon.anticon-delete {
          width: 16px;
          height: 16px;
        }
      }

    }
}

.ant-modal-content {
    padding: 24px;
    max-width: 300px;
    align-self: center;
    margin: auto;
    border-radius: 10px;

    .title {
        font-size: $bigFS;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .p-text {
        font-size: $mediumFS;
        font-weight: 500;
        color: $gray2Cl;
        letter-spacing: -0.8px;

        span {
            color: $purpleCl;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }

    .ant-modal-body,
    .ant-modal-footer {
        padding: 0;
    }

    .modal-categories {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 10px;
        border-bottom: 1px solid $grayCl;

        &.columnOnMobile {
            // flex-direction: column;

            .modal-categorie {
                background-color: transparent;
                padding: 0;
                margin-bottom: 0;
            }
        }
        h1 {
            font-size: $bigFS;
            width: 100%;
            margin-bottom: 5px;
            font-weight: 500;
        }

        .modal-categorie {
            width: 45%;
            background-color: $grayCl;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            padding: 5px;
            cursor: pointer;
            transition: all;
            margin-bottom: 10px;
            text-align: center;

            &.w-100 {
                width: 100%;
            }

            h1 {
                font-size: $mediumFS;
                margin: 0%;
            }

            &.selected {
                background-color: $greenCl;
                transition: all 0.5s ease;

                h1 {
                    color: #fff;
                    font-weight: 700;
                }
            }
        }
    }

    .ant-modal-footer {
        border: none;
        margin-top: 10px;
    }
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-top: 36px;

    .button-left,
    .button-right {
        padding: 11px 48px;
        flex: 1;
        font-size: $mediumFS;
        font-weight: 700;
        color: $gray3Cl;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray;
        border-radius: 5px;
        cursor: pointer;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: -moz-none;
        -o-user-select: none;
        user-select: none;
        overflow: hidden;
        border: none;

        &:hover {
            border: none;
        }
    }

    .button-left {
        order: 2;
    }

    .button-right {
        background-color: $purpleCl;
        color: #fff;
        order: 1;

        .ant-upload {
            width: 100%;
            height: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &.red {
            background-color: $redCl;
        }

        &.p0 {
            padding: 0;
        }
    }
}

.ant-upload-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.ant-upload-list-item {
    border: 1px solid $grayCl !important;
    border-radius: 10px !important;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
    margin: 0;
}

//text
.title {
    font-size: $exLargeFS;
    font-family: 'Manrope';
    font-weight: normal;
    font-style: normal;
}

.subtitle {
    font-size: $bigFS;
    font-family: 'Manrope';
    font-weight: 500;
    font-style: normal;
    color: $gray3Cl;
    line-height: 16px;

    span {
        font-weight: 700;
        color: #000;
        cursor: pointer;
    }
}

// text end

// keyframes
@keyframes fadeIn {
    from {
        height: 0;
        transform: translate3d(0, 0%, 0);
    }

    to {
        height: 185px;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInText {
    from {
        opacity: 0;
        transform: translate3d(0, -20%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@media only screen and (max-width: 600px) {
    .login-container {
        .inner-container {
            box-shadow: 0px 2px 2px 0px rgba(141, 141, 141, 0.11);
            width: 95%;
            background-color: white;
        }

        .footer-login {
            bottom: 0;
        }
    }

    .footer-container {
        margin-bottom: 0%;
        background-color: #f5f5f8;
        padding: 10px 0;

        .footer {
            li {
                margin: 0 5px;
            }
        }
    }

    .guestlist-header {
        margin-bottom: 80px;
        grid-template-columns: 60% auto;
    }

    .form-card {
        &.guestlist {
            .guestlist-info {
                grid-column: 1 / span 2;
            }
        }
    }

    .ant-modal-content {
        .modal-categories {
            &.columnOnMobile {
                flex-direction: column;

                .modal-categorie {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .guestlist-info {
        &.noWrap {
            flex-wrap: wrap !important;
        }
    }

    .modal-container {
        width: 80% !important;
    }

    .guestlist-row {
        flex-direction: column;
        .guestlist-input {
            &.midwidth {
                width: 100%;
            }
        }
    }
    .header-container {
        .header {
            padding: 0px 5%;

            .sidenav-close {
                display: block;

                button {
                    border: none;
                    height: fit-content;
                    display: flex;
                    align-items: center;
                    box-shadow: none;
                    color: white;
                }
            }

            .cell {
                &.nav-links {
                    display: none;
                }

                &.language {
                    display: none;
                }
            }
        }
    }

    .sidenav {
        margin-top: 80;
        height: 100vh;
        width: 100%;
        background: #b7b1bd;

        .sidenav-container {
            min-height: 70vh;
            width: 100%;
            background-color: white;
            animation: fadeIn 0.1s ease-in both;
            position: relative;

            .cell {
                display: flex;
                align-items: flex-start;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                padding-left: 16px;

                h2 {
                    font-size: $bigFS;
                    font-weight: 500;
                    text-transform: capitalize;
                    margin: 0%;
                    cursor: pointer;
                    color: $gray2Cl;
                    margin-bottom: 24px;

                    span {
                        margin-right: 10px;
                        top: 0.5px;
                    }

                    &.active {
                        color: $purpleCl;
                    }
                }

                h1 {
                    font-size: $mediumFS;
                    font-weight: 500;
                    color: $gray2Cl;
                }
            }
        }
    }

    .content {
        padding: 37px 5% 0 5%;

        .search-bar {
            .cell {
                width: 50%;
                justify-content: flex-end;

                .sorter {
                    &.open {
                        top: 33%;
                        right: 5%;
                    }
                }
            }
        }

        .stats {
            grid-template-columns: 50% 50%;
            grid-template-rows: repeat(3, 1fr);
            gap: 10px 5px;

            .card {
                &:nth-child(2) {
                    grid-column: 1 / span 2;
                    grid-row: 2;
                    flex-direction: row;
                    justify-content: space-between;
                }

                &:nth-child(4) {
                    grid-column: 1 / span 2;
                    grid-row: 3;
                    flex-direction: row;
                    height: 80px;

                    h1 {
                        display: none;
                    }
                }

                &.bottom {
                    .inner {
                        h2 {
                            display: block;
                            font-size: $mediumFS;
                            margin-left: 10px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .ant-collapse-content-box {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .data-container {
        &.h-75 {
            .data-row {
                min-height: 50px;

                .part1 {
                    grid-row: 2;
                }

                .part3 {
                    grid-row: 1;
                }

                .three-dot {
                    grid-row: 2;
                    grid-column: 2;
                }
            }
        }

        &.collapsed {
            padding: 0;
        }

        .data-row {
            display: grid;
            grid-template-columns: 80% 20%;
            grid-template-rows: repeat(3, auto);
            min-height: 155px;
            background-color: white;
            border-radius: 10px;
            padding: 0px;

            &.no-three-dot {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 16px;
            }
            &.account-venue {
                display: flex;
                align-items: center;

                .account-permissions {
                    align-items: flex-start;
                }

                .account-delete {
                    display: block;
                    position: relative;
                    left: -15px;
                    align-self: center;
                }

                .part2 {
                    padding-left: 0%;
                    padding-bottom: 0%;
                }
            }

            &:hover {
                grid-template-columns: 80% 20%;
                grid-template-rows: repeat(3, auto);
                transform: none !important;
            }

            .part1 {
                padding: 16px 0 0 16px;

                &.guestList {
                    h1 {
                        max-width: 200px;
                    }
                }
            }

            .part2 {
                grid-column: 1 / span 2;
                grid-row: 3;
                padding: 16px 0 16px 16px;
            }

            .part3 {
                grid-column: 1 / span 2;
                grid-row: 2;
                margin: 10px 0;
                padding: 16px 0 0 16px;
            }

            .three-dot {
                display: flex;
                align-content: center;
                justify-content: center;
                grid-column: 2;
                grid-row: 1;
                width: 100%;

                .three-dot-actions {
                    display: none;
                }

                .three-dot-icons {
                    align-self: center;
                }

                &:hover {
                    transform: none !important;

                    &.open {
                        .three-dot-actions {
                            display: block;
                            position: absolute;
                            background-color: #fff;
                            min-width: 180px;
                            box-shadow: 0 2px 10px rgb(33 43 54 / 8%);
                            border-radius: 5px;
                            z-index: 100;
                            right: 5%;
                            top: 80%;
                            padding: 4px;

                            .actions-row {
                                display: flex;
                                margin-bottom: 5px;
                                align-items: center;
                                padding: 6px 10px;
                                border-radius: 5px;
                                transition: background-color 0.3s ease;

                                &:hover {
                                    box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
                                    background-color: $grayCl;
                                }

                                h1 {
                                    font-size: $smallFS;
                                    color: #000;
                                    margin-left: 10px;
                                }

                                &.red {
                                    margin: 0%;

                                    h1 {
                                        color: $redCl;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .create-events-container {
        .create-events-inner-container {
            padding: 0 5%;

            .create-input {
                input {
                    width: 70%;
                }

                img#users {
                    display: none;
                }
            }
        }
    }

    .form-container {
        grid-template-columns: 1fr;
        gap: 0px;
    }

    .venue-top-cards {
        grid-template-columns: 1fr;
        // grid-template-rows: repeat(2, auto);
    }

    .venue-container {
        width: 100%;
        // min-height: 75vh;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .venue-nav {
        .cell {
            &.nav-links {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .venue-stats-container {
        .venue-stats {
            &.custom-app-tab {
                .form-card {
                    &.space-between {
                        grid-column: 1 / span 2;
                    }
                }
            }
            .venue-stats-row {
                &.top,
                &.bottom {
                    grid-template-columns: 100%;
                }
            }
        }
    }

    .venue-settings-container {
        .venue-settings-gallery {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            height: 350px;

            button {
                right: 8%;
                top: 340px;
            }

            .gallery-big {
                &.left {
                    grid-column: 1 / span 2;
                    grid-row: 1;
                }

                &.right {
                    grid-column: 1 / span 2;
                    grid-row: 3;
                }
            }

            .gallery-small {
                &.top {
                    grid-column: 1;
                    grid-row: 2;
                }

                &.bottom {
                    grid-column: 2;
                    grid-row: 2;
                }
            }
        }

        .venue-data-container {
            grid-template-columns: 100%;
            grid-template-rows: repeat(2, 1fr) repeat(3, 0.5fr);

            .data-name {
                justify-content: center;
            }

            .data-venue {
                grid-row: 4;
            }
        }
    }

    .guestlist-info-main {
        &.guestlist-name {
            max-width: 200px;
        }
    }
}

@media only screen and (max-width: 1115px) {
    .login-container {
        .svg-container {
            opacity: 0.1;
        }
    }
}

.ant-segmented-item {
    border-radius: 4px;
}

.ant-select-selection-item {
    // margin: 0 10px;
    margin-left: 5px;
    border: none;
    cursor: pointer;
    font-size: 17px!important;
    font-weight: 500;
    border-radius: 0px;
    padding: 0 !important;
    color: black!important;
    margin-bottom: 4px;
}

.ant-input-number-out-of-range input {
    color: #000;
}

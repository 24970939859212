.venues {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s ease;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;

    .venues-empty {
        display: flex;
        width: 100%;

        h1 {
            font-size: $mediumFS;
            font-weight: 500;
            color: $gray2Cl;
            margin-left: 10px;
        }

        &.add {
            h1 {
                font-size: $smallFS;
                font-weight: 700;
                color: $gray2Cl;
                min-width: 10%;
            }
        }
    }

    &.selected {
        cursor: default;
        border-radius: 0px;
        justify-content: center;
    }
}

.venue-default {
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #fff;

    .info {
        display: flex;
        width: 100%;
        border-bottom: 1px solid $grayCl;
        padding: 20px 0px;
        align-items: center;
        width: 100%;

        &.selected {
            justify-content: space-between;

            .item-text {
                flex-direction: row;
                width: 85%;
                justify-content: space-between;

                .selected-card {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 85%;
                    flex-direction: column;
                }

                .selected-card-edit {
                    width: 15%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        &.center {
            justify-content: center;
            padding: 12px 0px;
            cursor: pointer;

            h1 {
                font-size: $mediumFS;
                font-weight: 700;
            }
        }

        span {
            filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.07));
        }

        .item-text {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h1 {
                font-size: $mediumFS;
                font-weight: 700;
                color: $gray3Cl;
                margin-bottom: 7px;
            }

            h2 {
                font-size: $mediumFS;
                font-weight: 500;
                color: $gray2Cl;
            }
        }
    }
}

.venue-top-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 100%;
    gap: 10px;
    min-height: 258px;
    margin-bottom: 30px;

    .top-cards-logo {
        background-color: $black;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        cursor: pointer;
        height: 100%;

        .top-card-name {
            h1,
            h2 {
                color: #fff;
                margin: 0%;
                font-weight: 700;
            }

            h1 {
                font-size: $midLarge;
                letter-spacing: 0.3px;
                margin-top: 5px;
                margin-bottom: 10px;
            }

            h2 {
                font-size: $smallFS;
                letter-spacing: 0.6px;
            }

            button {
                background: hsla(0, 0%, 100%, 0.1);
                border: none;
                padding: 5px 16px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                h1 {
                    margin: 0%;
                    margin-left: 5px;
                    color: #fff;
                    font-size: $mediumFS;
                    font-weight: 700;
                }
            }
        }

        .top-card-venue-logo {
            width: 110px;
            height: 110px;
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.venue-nav {
    background-color: #fff;
    filter: drop-shadow(0 2px 2px rgba(141, 141, 141, 0.11));
    border-radius: 12px;
    padding: 12px 14px;
    margin-bottom: 24px;
}

.venue-stats-container {
    .title {
        font-size: $midLarge;
        font-weight: 700;
        margin: 0%;
        margin-bottom: 18px;
    }

    .venue-stats-actions {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        button {
            border: none;
            background-color: $grayCl;
            display: flex;
            align-items: center;
            margin: 0 20px 20px 0;
            border-radius: 5px;
            padding: 5px 16px;

            h1 {
                color: $gray3Cl;
                font-size: $mediumFS;
                margin-left: 5px;
                font-weight: 700;
            }

            &.live,
            &.historical {
                h1 {
                    color: #fff;
                }
            }

            &.live {
                background-color: $redCl;
            }

            &.historical {
                background-color: $purpleCl;
            }
        }
    }

    .venue-stats {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        width: 100%;
        justify-content: space-between;

        &.custom-app-tab {
            gap: 10px;
            grid-template-rows: 1fr;
        }

        .venue-stats-row {
            display: grid;
            gap: 10px;

            &.top {
                grid-template-columns: repeat(2, 1fr);
                grid-column: 1 / span 2;
                grid-row: 1;
                margin-bottom: 24px;
            }

            &.bottom {
                grid-template-columns: 0.8fr 1.5fr 1fr;
                grid-column: 1 / span 2;
                grid-row: 2;
            }

            .venue-no-stats {
                width: 100%;
                min-height: 80%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
        }
    }

    .custom-app-description {
        grid-column: 1 / span 2;
        p,
        li {
            font-weight: 500;
        }
    }
}

.stats-row-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 10px;

    h1 {
        color: $black;
        font-size: $mediumFS;
        font-weight: 600;
    }

    button {
        border: none;
        background-color: #f5f5f8;
        border-radius: 5px;
        padding: 5px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $gray3Cl;
        font-weight: 700;
        text-transform: capitalize;
    }

    .stats-row-switcher {
        display: none;
        position: absolute;
        right: 10px;
        top: 100%;
        background-color: #fff;
        box-shadow: 0 2px 2px hsl(0deg 0% 55% / 11%);
        padding: 5px 12px;
        border-radius: 5px;
        cursor: pointer;

        .stats-row-option {
            padding: 5px 0;
        }

        &.open {
            display: block;
            transition: all 1s ease;
        }
    }
}

.stats-row-data {
    min-height: 80%;
    display: flex;
    align-items: flex-end;
}

.stats-row-progress {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .stats-row-explained {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px 0;
        border-bottom: 1px solid #ebebf5;

        h1 {
            font-size: $mediumFS;
            font-weight: 700;

            &.orange {
                color: $orangeCl;
            }

            &.second-orange {
                color: $secondOrangeCl;
            }

            &.red {
                color: $redCl;
            }

            &.green {
                color: $greenCl;
            }

            &.blue {
                color: $blueCl;
            }

            &.gray {
                color: $gray3Cl;
            }

            &.purple {
                color: $purpleCl;
            }

            &.yellow {
                color: $yellowCl;
            }
        }

        h2 {
            font-size: $mediumFS;
            font-weight: 500;
            color: $gray3Cl;
        }
    }
    .text-list {
        width: 40%;

        &.grid {
            display: grid;
            grid-template-columns: repeat(2, 50%);
            width: 50%;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
        }

        .stat-value {
            font-size: $largeFS;
            margin: 0%;
            margin-bottom: 2px;
            font-weight: 700;
            text-transform: capitalize;

            &.guestlist {
                font-size: $mediumFS;
                margin-bottom: 0;
            }

            &.orange {
                color: $orangeCl;
            }

            &.second-orange {
                color: #ff8500;
            }

            &.red {
                color: $redCl;
            }

            &.green {
                color: $greenCl;
            }

            &.blue {
                color: $blueCl;
            }

            &.gray {
                color: $gray3Cl;
            }

            &.purple {
                color: $purpleCl;
            }

            &.yellow {
                color: #fec400;
            }
        }

        .stat {
            margin-bottom: 14px;
            color: $gray3Cl;
            font-size: $mediumFS;
            font-weight: 500;

            &.guestlist {
                margin-bottom: 8px;
            }

            &.capitalize {
                text-transform: capitalize;
            }
        }

        .span-two-columns {
            grid-column: 1 / span 2;
        }
    }
}
.venue-settings-container {
    .venue-settings-gallery {
        width: 100%;
        height: 260px;
        display: grid;
        grid-template-columns: 1fr 0.4fr 1fr;
        grid-template-rows: repeat(2, 50%);
        gap: 10px;
        margin-bottom: 34px;

        button {
            position: absolute;
            right: 24px;
            top: 260px;
            border: none;
            padding: 5px 12px;
            display: flex;
            align-items: center;
            border-radius: 5px;
        }

        .gallery-big,
        .gallery-small {
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            width: 100%;
            height: 100%;
            background-color: $grayCl;
        }

        .gallery-big {
            &.left {
                grid-column: 1;
                grid-row: 1 / span 2;
            }

            &.right {
                grid-column: 3;
                grid-row: 1 / span 2;
            }
        }

        .gallery-small {
            &.top {
                grid-column: 2;
                grid-row: 1;
            }

            &.bottom {
                grid-column: 2;
                grid-row: 2;
            }
        }
    }

    .venue-data-container {
        display: grid;
        grid-template-columns: 0.7fr 1fr;
        grid-template-rows: repeat(3, 0.5fr);
        gap: 20px;

        .data-name {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;

            .data-name-logo {
                width: 140px;
                position: relative;
                overflow: hidden;
                height: 140px;
                border-radius: 10px;
                margin-bottom: 24px;
            }
        }
        .data-venue {
            display: flex;
            flex-direction: column;
        }

        .data-name,
        .data-inputs,
        .data-venue {
            .form-card {
                margin: 0%;
            }
        }

        .data-inputs {
            .data-sync {
                margin-bottom: 24px;
                padding: 0 24px;
                background-color: black;
                display: flex;
                align-items: center;
            }

            .data-download {
                border: none;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 12px;
                background-color: $grayCl;
                border-radius: 5px;

                h1 {
                    margin-left: 10px;
                    font-weight: 700;
                    color: $gray3Cl;
                    font-size: $mediumFS;
                }
            }
        }
    }
}

.check-in-text {
    font-size: $mediumFS;
    color: $gray2Cl;
    padding: 10px;
    span {
        color: $gray3Cl;
    }
}

// from vue app start

.d-doughnut {
    svg {
        .progress-ring__circle {
            transform: rotate(-90deg);
            stroke-linecap: round;
            transform-origin: center;
            transition: all 200ms ease;
        }

        &.hide {
            display: none;
        }

        .progress-ring__bg {
            stroke: $grayCl;
        }

        .text-color {
            fill: $gray3Cl;
        }
    }
}

.d-tooltip {
    text-align: center;
    opacity: 0;
    will-change: contents;
    position: absolute;
    top: -44px;
    transition: all 100ms ease;
    transform: translateY(5px);
    border-radius: 4px;
    background-color: #333a40;
    color: #fff !important;
    padding: 4px 8px;
}

.chart-content {
    width: 100%;
    display: grid;
    grid-row-gap: 12px;
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas: 'data' 'indicator' 'label';

    &.age {
        grid-template-areas: 'data data data data data' 'indicator indicator indicator indicator indicator' 'label label label label label';
        grid-template-columns: none;

        .bar {
            height: 90px;
        }
    }

    .bar {
        height: 116px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .data {
            width: 20px;
            border-radius: 7px;
            background-color: rgba(3, 153, 218, 0.5);
            display: flex;
            position: relative;
            justify-content: center;
            align-items: flex-end;

            &:hover {
                .d-tooltip {
                    opacity: 1;
                    transform: translateY(0);
                }
            }

            &.zero {
                min-height: 4px;
                background-color: $grayCl;
            }

            &.current {
                background-color: $redCl;
            }

            .current-visitors {
                margin: 2px;
                border-radius: 6px;
                width: 16px;
                background-color: $redCl;
            }
        }
    }

    .indicator {
        border-top: 1px solid $grayCl;
        display: flex;
        justify-content: center;

        .inner-line {
            width: 1px;
            height: 8px;
            background-color: $grayCl;
        }
    }

    .label {
        display: flex;
        font-size: 12px;
        justify-content: center;
        color: $gray3Cl;
    }
}

.origin-distribution {
    padding: 10px;
    border-top: 1px solid rgba(231, 233, 237, 1);
    display: flex;
    justify-content: space-between;

    .city,
    .occurrence {
        font-size: 14px;
        line-height: 17px;
    }
}

// from vue app end

.content {
    position: relative;
    top: 80px;
    padding-top: 37px;
    max-width: 1200px;
    margin: 0 auto;

    .license-expired {
        border-radius: 12px;
        background: linear-gradient(90deg,#ff729f  0%, #ff6395 100%);
        padding: 24px;
        margin-bottom: 37px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .message {
            margin-left: 10px;
            h1 {
                font-size: $bigFS;
                color: #fff;
                font-weight: bold;
            }

            h2 {
                font-size: $mediumFS;
                color: #fff;
            }
        }

        .button {
            width: 100%;
            height: 40px;
            background-color: #fff;
            margin-top: 10px;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $bigFS;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            cursor: pointer;

            &:hover {
                color: $purpleCl;
            }
        }
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h1 {
            font-size: $midLarge;
            font-weight: 700;
        }

        .header-buttons {
            display: flex;

            .create-button,
            .import-button {
                height: 32px;
                background-color: $purpleCl;
                padding: 0px 20px;
                border-radius: 29px;
                display: flex;
                align-items: center;
                cursor: pointer;
                transform: scale(1);
                transition: transform 0.3s ease;

                &.disabled {
                    background-color: $gray2Cl;
                    cursor: not-allowed;
                    &:hover {
                        transform: scale(1);
                    }
                }
                &:hover {
                    transform: scale(1.03);
                    transition: transform 0.3s ease;
                }

                .text {
                    font-size: $mediumFS;
                    color: white;
                    font-weight: 700;
                    margin-left: 10px;
                }
            }

            .import-button {
                background-color: $grayCl;
                margin-right: 10px;

                .text {
                    color: $gray3Cl;
                }
            }
        }
    }

    .search-bar {
        padding: 18px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $grayCl;
        border-left: none;
        border-right: none;

        .cell {
            display: flex;
            align-items: center;
            cursor: pointer;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: -moz-none;
            -o-user-select: none;
            user-select: none;

            .sorter {
                display: none;

                &.open {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    background-color: #fff;
                    min-width: 180px;
                    box-shadow: 0 2px 10px rgb(33 43 54 / 8%);
                    border-radius: 5px;
                    z-index: 100;
                    right: 0%;
                    top: 45%;
                    padding: 4px;

                    .sort {
                        font-size: $smallFS;
                        font-weight: 500 !important;
                        color: #000;
                        margin-bottom: 5px;
                        padding: 6px 10px;
                        border-radius: 5px;
                        transition: background-color 0.3s ease;
                        border: none;
                        background-color: #fff;

                        &:hover {
                            box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
                            background-color: $grayCl;
                        }
                    }
                }
            }

            input {
                border: none;
                box-shadow: none;
                background-color: #f5f5f8;
                color: $gray2Cl;
                font-size: $mediumFS;
                font-weight: 500;

                &::placeholder {
                    color: $gray2Cl;
                    font-size: $mediumFS;
                    font-weight: 500;
                    margin-left: 10px;
                }
            }

            h1 {
                color: $gray2Cl;
                font-size: $mediumFS;
                font-weight: 500;
                margin-left: 10px;

                &.right {
                    margin-left: 0px;
                    margin-right: 10px;
                }
            }
        }
    }

    .stats {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 0.3fr;
        gap: 10px;
        justify-content: space-between;
        align-items: center;

        .card {
            background-color: #fff;
            box-shadow: 0 2px 2px $grayCl;
            border-radius: 5px;

            &.top,
            &.bottom {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 15px;

                .inner {
                    display: flex;
                    align-items: center;

                    h1 {
                        font-size: $mediumFS;
                        margin-left: 10px;

                        span {
                            font-weight: 700;
                        }
                    }

                    h2 {
                        display: none;
                    }
                }
            }

            &.top {
                height: 80px;
            }

            &.bottom {
                height: 50px;
                grid-column: 1 / span 3;
                grid-row: 2;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}

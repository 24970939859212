.statescreens-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 50px;

  h1 {
    font-size: $bigFS;
    font-weight: 500;
  }

  p {
    font-size: $mediumFS;
    font-weight: 500;
    color: $gray2Cl;
  }
}
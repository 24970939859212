.create-events-container {
    width: 100%;
    left: 0%;

    .create-events-inner-container {
        max-width: 1200px;
        margin: 0 auto;

        .top-buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;
            position: relative;

            .publish-button {
                margin-right: 12px;
                color: $gray3Cl;
                transition: margin-right 1s ease;

                .roated-arrow {
                    transform: rotate(90deg);
                }
            }

            .three-dot {
                position: relative;
                display: flex;
                margin-left: 10px;
                transition: transform 0.2s ease;
                align-content: center;

                .three-dot-actions {
                    display: none;
                }

                .three-dot-icons {
                    align-self: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:hover {
                    transform: scale(1.2);

                    .three-dot-actions {
                        display: block;
                        position: absolute;
                        background-color: #fff;
                        min-width: 180px;
                        box-shadow: 0 2px 10px rgb(33 43 54 / 8%);
                        border-radius: 5px;
                        z-index: 100;
                        right: 5%;
                        top: 100%;
                        padding: 4px;

                        .actions-row {
                            display: flex;
                            margin-bottom: 5px;
                            align-items: center;
                            padding: 6px 10px;
                            border-radius: 5px;
                            transition: background-color 0.3s ease;

                            &:hover {
                                box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
                                background-color: $grayCl;
                            }

                            h1 {
                                font-size: $smallFS;
                                color: #000;
                                margin-left: 10px;
                            }

                            &.red {
                                margin: 0%;

                                h1 {
                                    color: $redCl;
                                }
                            }
                        }
                    }
                }
            }
        }

        .draft-alert {
            width: 100%;
            background-color: #000;
            border-radius: 12px;
            padding: 16px 24px;
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            h1,
            h2 {
                color: white;
                margin-left: 10px;
            }

            h1 {
                font-size: $mediumFS;
                font-weight: 700;
            }

            h2 {
                font-size: $smallFS;
                font-weight: 500;
            }
        }

        .ant-upload {
            width: 100%;
            height: 270px;
            position: relative;
            margin-bottom: 36px;

            button {
                position: absolute;
                right: 0;
                bottom: 0;
                margin: 0 24px 24px 0;
                border-radius: 5px;
                padding: 5px 16px;
                display: flex;
                align-items: center;
                color: $gray3Cl;
                font-weight: 700;
                border: none;
                box-shadow: 0 0 5px 0.4px $grayCl;

                span {
                    margin-left: 5px;
                }

                &:hover {
                    background-color: $grayCl;
                }
            }

            .uploadButton {
                &.with-image {
                    min-height: 400px;
                    position: relative;
                    width: 100%;
                }
                h1 {
                    font-size: $largeFS;
                    font-weight: 700;
                    color: #000;
                }

                h2 {
                    font-size: $mediumFS;
                    font-weight: 500;
                    color: $gray3Cl;
                    margin-bottom: 24px;
                }

                h3 {
                    font-size: $smallFS;
                    font-weight: 500;
                    color: $gray2Cl;
                }
            }
        }

        .message {
            font-size: $smallFS;
            color: $gray2Cl;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .venus-selector {
            width: 100%;
            overflow-y: scroll;

            &.open {
                animation: openVenu 0.3s ease-in forwards;
            }

            &.close {
                animation: closeVenu 0.3s ease-out forwards;
            }

            &.hide {
                display: none;
            }

            @keyframes openVenu {
                from {
                    height: 0px;
                }

                to {
                    height: 300px;
                }
            }

            @keyframes closeVenu {
                from {
                    height: 300px;
                }

                to {
                    height: 0px;
                    display: none;
                }
            }
        }

        .venus-selector-item {
            display: flex;
            margin-bottom: 10px;
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;

            span {
                filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.07));
            }

            .item-text {
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h1 {
                    font-size: $mediumFS;
                    font-weight: 700;
                    color: $gray3Cl;
                    margin-bottom: 4px;
                }

                h2 {
                    font-size: $mediumFS;
                    font-weight: 500;
                    color: $gray2Cl;
                }

                div {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .ant-upload.ant-upload-select-picture-card {
            border: none;
        }

        .event-cover {
            min-height: 400px;
            position: relative;
            width: 100%;
            // z-index: -1;

            .ant-upload {
                min-height: 400px;
                width: 100%;
                position: relative;
                // z-index: -1;
            }
            // z-index: -1;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // overflow: hidden;

            button {
                position: absolute;
                z-index: 50;
                bottom: 24px;
                right: 24px;
            }
        }
    }
}

.form-container {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 36px;
    padding-bottom: 100px;

    .guests-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px;
        cursor: pointer;

        h1,
        h2 {
            font-size: $mediumFS;
            color: $gray2Cl;
            font-weight: 700;
        }

        h2 {
            font-weight: 500;
        }
    }
}
.tags-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    .top-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h1 {
            font-size: $bigFS;
            font-weight: 500;
            color: $gray2Cl;
            margin-bottom: 10px;
        }

        h2 {
            font-size: $mediumFS;
            font-weight: 500;
            color: $gray2Cl;
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
                color: #000;
                transition: color 0.3s ease;
            }
        }
    }

    .tag {
        display: flex;
        align-items: center;
        padding: 6px 10px;
        background-color: $grayCl;
        margin: 0 4px 8px 0;
        border-radius: 10px;
        color: $gray3Cl;
        font-weight: 500;
        font-size: $smallFS;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.selected {
            background-color: $greenCl;
            color: #fff;
            font-weight: 700;
            transition: background-color 0.3s ease;
        }
    }
}

.form-card {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
    padding: 12px;
    margin-bottom: 24px;
    position: relative;

    &.mb0 {
        margin: 0%;
    }

    &.p10 {
        padding: 10px;
    }

    &.h-230 {
        height: 230px;
    }

    &.scroll {
        overflow: scroll;
    }

    &.guestlist-total-guests {
        background: linear-gradient(360deg, #ebebf5 0%, #ffffff 99.99%);
        box-shadow: 0px 2px 2px rgba(141, 141, 141, 0.11);
    }

    &.account-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;

        h1,
        h2 {
            margin: 0%;
        }

        h1 {
            font-size: $midLarge;
            font-weight: 700;
        }

        h2 {
            font-size: $mediumFS;
            font-weight: 500;
            color: $gray3Cl;
        }
    }

    &.check-in-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &.space-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &.guestlist {
        grid-column: 1 / span 2;
        margin-top: 16px;
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        gap: 36px;
        justify-content: space-between;
        align-items: center;
    }
}

.calendar-container {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 1fr auto;
    align-items: center;

    .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 22px;

        h1 {
            font-size: $midLarge;
            font-weight: 400;
            color: #000;
        }

        h2 {
            font-size: $smallFS;
            font-weight: 700;
            color: $redCl;
            letter-spacing: 0.6px;
        }
    }

    .range {
        grid-column: 1 / span 2;

        .ant-picker {
            width: 50%;
            display: flex;
            flex-direction: column;
        }

        .ant-picker-active-bar {
            display: none;
        }

        .ant-picker-range-separator, .ant-picker-suffix {
            display: none;
        }

        .ant-picker-range, .ant-picker-focused {
            border: 0;
            box-shadow: none;
        }
    }

    h3 {
        font-size: $mediumFS;
        color: $gray3Cl;
        font-weight: 500;

        span {
            font-weight: 700;
            color: #000;
        }
    }
}

.back-button {
    display: flex;
    cursor: pointer;
    transition: transform 0.3s ease;
    transform: translateX(0px);
    margin-bottom: 15px;

    h1 {
        margin-left: 5px;
        font-size: $mediumFS;
        font-weight: 700;
        color: $purpleCl;
    }

    &:hover {
        transform: translateX(-5px);

        h1 {
            color: #000;
        }
    }
}

.save-button,
.publish-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 16px;
    border-radius: 29px;
    background-color: $grayCl;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s ease;

    h2 {
        font-size: $smallFS;
        font-weight: 700;
    }

    &:hover {
        transform: scale(1.03);
        transition: transform 0.3s ease;
    }
}

.save-button {
    background-color: $purpleCl;

    h2 {
        color: white;
    }
}

.ml {
    margin-left: 12px;
}
